import React from 'react';
import styled from '@emotion/styled';
import { device } from './GlobalStyles';
//components
import { DoctorCard } from './cards/DoctorCard';

const StyledDoctors = styled.section`
    padding-left: 1em;
    h4 {
        padding: 4em 0em 3em 0em;
    }
    h2 {
        margin-bottom: 1em;
    }
    .cards {
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
    }
    @media ${device.tablet} {
        padding-bottom: 72px;
        h4,
        h2 {
            text-align: center;
        }
        .cards {
            max-width: 1000px;
            margin: 0 auto;
            flex-wrap: wrap;
            justify-content: center;
            padding-bottom: 32px;
            div {
                margin: 10px;
            }
        }
    }
    @media ${device.desktopL} {
        h4,
        h2 {
            text-align: center;
        }
        .cards {
            flex-wrap: nowrap;
            max-width: 1900px;
        }
    }
`;

const doctors = [
    {
        name: 'DR. M. REZA IRANMANESH',
        title: 'DMD, MSD, PA',
        image: 'dr-reza-card-new',
        path: 'dr-m-reza-iranmanesh/',
    },
    {
        name: 'DR. FRESHTE ESFAHANIAN',
        title: 'DDS',
        image: 'dr-freshte-card-new',
        path: 'dr-freshte-esfahanian/',
    },
    {
        name: 'DR. NIMA IRANMANESH',
        title: 'DMD',
        image: 'dr-nima-card-new',
        path: 'dr-nima-iranmanesh/',
    },
    {
        name: 'DR. NIKA IRANMANESH',
        title: 'DMD',
        image: 'dr-nika-card-new',
        path: 'dr-nika-iranmanesh/',
    },
    {
        name: 'DR. ARMITA MASHKOURI',
        title: 'DMD, MS',
        image: 'dr-armita-card-new',
        path: 'dr-armita-mashkouri/',
    },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DoctorsSlide: React.FC<{ images: any }> = ({ images }) => {
    return (
        <StyledDoctors>
            <h4>OUR DOCTORS</h4>
            <h2>MEET OUR DOCTORS</h2>
            <div className="cards">
                {doctors.map(doctor => (
                    <DoctorCard
                        path={doctor.path}
                        images={images}
                        key={doctor.name}
                        name={doctor.name}
                        title={doctor.title}
                        imageName={doctor.image}
                    />
                ))}
            </div>
        </StyledDoctors>
    );
};
